@import '../layout/_vars';

.brandLogo {
    max-width: 150px;
    margin: 0 auto 2rem;
    display: block;

    @include media-breakpoint-down(sm) {
        max-width: 100px;
        margin: 0 auto 1rem;
    }
}

.contentWrapper {
    background-image: linear-gradient(to bottom, #a7a6cb 0%, #8989ba 52%, #8989ba 100%);
    background-attachment: fixed;
    color: #fff;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    position: relative;
    padding: 50px 0;
}

.content {
    max-width: 600px;
    padding: 120px 50px;
    box-shadow: rgba(#000, .2) 0 0 25px;
    border-radius: 5px;
    background-image: linear-gradient(
        119deg,
        hsl(var(--bs-primary-h), 70%, 60%),
        hsl(var(--bs-primary-h), 65%, 34%)
    );

    .contentHeader {
        text-align: center;

        h1, p {
            font-weight: 300;
        }
        h1 {
            text-transform: uppercase;
        }
        p {
            margin-bottom: 1.5rem;
            font-size: 1.2rem;
            letter-spacing: 0.2px;
        }

        @include media-breakpoint-down(sm) {
            h1 {
                font-size: 2rem;
            }
        }
    }

    :global {
        .form-control {
            background: transparent;
            border: 0;
            border-radius: 0;
            border-bottom: 1px solid #fff;
            color: #fff;
            transition: border-bottom-width .1s;
            padding-left: 5px;
            padding-right: 5px;

            &:focus {
                border-bottom-width: 3px;
                box-shadow: none;
            }
            &::placeholder {
                color: rgba(#fff, .6);
            }
        }

        .input-group-text {
            background-color: transparent;
            border: 0;
            border-bottom: 1px solid #fff;
            border-radius: 0;
            color: #ccc;
        }

        .btn {
            text-transform: uppercase;
        }
        .btn-link {
            color: rgba(#fff, .85);
            &:hover {
                color: rgba(#fff, .85);
            }
        }
    }
}

.totpForm {
    max-width: 300px;
    text-align: center;
    margin: 0 auto;

    input {
        text-align: center;
        font-size: 1.35rem;
    }
}
